import Image from "next/image";
import Link from "next/link";
import { Kumbh_Sans } from "next/font/google";
import { GoDotFill } from "react-icons/go";
import Animate from "../Common/AnimateOnScroll";

const kumbhsans = Kumbh_Sans({
  subsets: ["latin"],
  weight: ["700"],
});

const TechnologyCard = ({ img, title, techs, alt, link }) => {
  return (
    <Animate.ScaleIn>
    <div
      className="flex p-5 md:h-[155px] items-start space-x-4 rounded-[10px] shadow-lg bg-white"
      style={{ boxShadow: "0px 4px 22px rgba(0, 0, 0, 0.12)" }}
    >
      {/* <Image src={img} width={33} height={33} alt={alt} /> */}
      <div className="flex flex-col space-y-4">
        <div className="flex gap-5">
        <Image src={img} width={28} height={28} alt={alt} /> 
        <Link
          href={link}
          className={`text-base md:text-[20px] text-primary font-semibold ${kumbhsans.className}`}
        >
          {title}
        </Link>
        </div>
      
        <div className="grid grid-cols-2 gap-2">
          {techs?.map((tech, i) => {
            return (
              <div className="flex space-x-2 items-center" key={i}>
                <GoDotFill color="#243C93" />
                <span className="text-xs md:text-base">
                  {tech}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    </Animate.ScaleIn>
  );
};

export default TechnologyCard;
