import Image from "next/image";
import TechnologyCard from "./TechnologyCard";

const Technologies = () => {
  return (
    <div
      id="technologies"
      className="w-full relative flex items-center justify-center px-8 xl:px-16 py-4 mt-20  mx-auto"
    >
      <div
        className="max-w-screen-xl mx-auto flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-4
       bg-[#E9EEFF] rounded-[20px] p-5
]"
      >
        <div className="flex flex-col space-y-2 col-span-1">
          <div className="flex flex-col items-start w-full">
            <p
              className={`text-2xl xl:text-5xl font-bold text-secondary fontNew`}
            >
              Technologies
            </p>
            <p
              className={`text-2xl xl:text-5xl font-bold text-primary fontNew`}
            >
              We Use
            </p>
          </div>
          <p className="text-base font-normal text-justify fontNew max-w-[400px]">
            ZySoftec leverages a variety of cutting-edge technologies to develop
            and deliver high-quality softwares. Our expertise spans&nbsp;
            <a
              className="font-semibold"
              target="_blank"
              href="/services/deployment/domain-hosting"
            >
              multiple domains
            </a>
            &nbsp; and industries, enabling us to select the most suitable
            technologies based on our clients' specific needs and project
            requirements.
          </p>
        </div>

        <div className=" grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-5 xl:gap-5 col-span-3  ">
          <TechnologyCard
            img={"/images/frontend.svg"}
            title="Frontend Development"
            techs={["React Js", "React Native", "Html Css", "Next Js"]}
            key={1}
            alt={"frontend technology"}
            link={"/services/web/frontend-development"}
          />
          <TechnologyCard
            img={"/images/backend.svg"}
            title="Backend"
            techs={["Node Js", "Express Js", "PHP"]}
            alt={"backend technology"}
            key={2}
            link={"/services/web/backend-api-development"}
          />
          <TechnologyCard
            img={"/images/db.svg"}
            title="Database"
            techs={["MongoDB", "Postgres SQL", "Firebase", "MySQL"]}
            key={3}
            alt={"databases"}
            link={"/services/app/app-integration"}
          />
          <TechnologyCard
            img={"/images/framework.svg"}
            title="Frameworks"
            techs={[
              "Ant Design",
              "Tailwind CSS",
              "Bootstrap",
              "Material UI",
              "Adonis Js",
            ]}
            alt={"javascript frameworks"}
            key={4}
            link={"/services/web/third-party-apis-integration"}
          />
          <TechnologyCard
            img={"/images/graphics.svg"}
            title="Designing"
            techs={["Figma", "Illustrator", "Photoshop", "Adobe XD"]}
            key={5}
            alt={"designing in figma"}
            link={"/services/design/web-ui-design"}
          />
          <TechnologyCard
            img={"/images/graphics.svg"}
            title="SEO"
            techs={[
              "Google Analytics ",
              "Semrush",
              "Google Search console",
              "Google Keyword Planner",
            ]}
            key={6}
            alt={"seo"}
            link={"/services/design/web-ui-design"}
          />
        </div>
      </div>
      <Image
        src={"/images/tech-circle.svg"}
        width={200}
        height={200}
        className="absolute left-0 -top-10 -z-10 xl:w-[500px] xl:h-[500px]"
        alt="web services usa"
      />
    </div>
  );
};

export default Technologies;
